import { useAuth } from "context/AuthContext";
import { Locked } from "pages/Locked";
import React, { ReactNode, useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// import { AuthContext } from "../context/AuthContext";

interface IPublicRoute {
	children: ReactNode;
}

export function PublicRoute({ children }: IPublicRoute) {
	const location = useLocation();
	const navigate = useNavigate();
	const { isAuthenticated, isGameEnabled } = useAuth();

	useEffect(() => {
		if (!isGameEnabled && location.pathname !== "/locked") {
			navigate("/locked");
		}
	}, [isGameEnabled, location.pathname]);

	return <>{children}</>;
}

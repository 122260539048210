import { UIElementProps } from "types/prop-types";

import white from "assets/images/shape-white.svg";
import white2 from "assets/images/shape-white-2.svg";
import white3 from "assets/images/shape-white-3.svg";
import line from "assets/images/shape-line.svg";
import curve from "assets/images/curve-white.svg";

const Sources : any = {
	white,
	white2,
	white3,
	line,
	curve
}

export default function Shape({name, className}:UIElementProps){
	return <img src={Sources[name]} className={`pointer-none ${className}`} />
}

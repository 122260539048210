import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";

interface CoopContextData {
	coopInfo?: any;
	coop?: any[];
	coopAtualizada?: boolean;
	pdgc?: any[];
	negocios?: any[];
	categoria?: string;
	ranking?:any;
	seloCoop:boolean;
	setCoop: any;
	setCoopInfo: any;
	setPdgc: any;
	setNegocios: any;
	setCategoria: any;
	setRanking:any;
	setCoopAtualizada:any;
	setSeloCoop:any;
	reset: () => void;
}

interface CoopProviderProps {
	children: ReactNode;
}

const CoopContext = createContext({} as CoopContextData);

function CoopProvider({ children }: CoopProviderProps) {
	const [categoria, setCategoria] = useState();
	const [coopInfo, setCoopInfo] = useState();
	const [coopAtualizada, setCoopAtualizada] = useState(false);
	const [seloCoop, setSeloCoop] = useState(false);
	const [coop, setCoop] = useState();
	const [pdgc, setPdgc] = useState();
	const [negocios, setNegocios] = useState();
	const [ranking,setRanking] = useState(0);

	function reset() {
		setCoop(undefined);
		setPdgc(undefined);
		setNegocios(undefined);
		setCoopInfo(undefined);
		setSeloCoop(false);
		setCoopAtualizada(false);
	}

	const value = { coopInfo, coop, coopAtualizada, pdgc, negocios, categoria, ranking, seloCoop, setSeloCoop, setCoopAtualizada, setRanking, setCategoria, setCoop, setCoopInfo, setPdgc, setNegocios, reset };

	return <CoopContext.Provider value={value}>{children}</CoopContext.Provider>;
}

function useCoop() {
	const context = useContext(CoopContext);
	if (context === undefined) {
		throw new Error("useCoop must be used within a CoopProvider");
	}
	return context;
}

export { CoopProvider, useCoop };

import { ModalContext } from "context/ModalContext";
import { useContext, useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import ReactModal from "react-modal";

export function LoadingModal(props: any, onAnswer?: Function) {
	const modalID = "loading";
	const [modalIsOpen, setIsOpen] = useState(true);
	const { children } = props;

	ReactModal.setAppElement("#modal");

	return (
		<ReactModal
				shouldCloseOnOverlayClick={false}
				isOpen
				contentLabel="Example Modal"
				portalClassName="fixed z-50 w-full h-full top-0 left-0"
				overlayClassName="fixed w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
				contentElement={() => (
					<div>
						<div className="bg-white rounded-full p-3 flex flex-col space-y-2 flex-none mx-5">
							<div className="flex flex-row items-center text-blue-500">
								<AiOutlineLoading
									className="animated animate-spin text-orange-500"
									size={32}
								/>
							</div>
						</div>
					</div>
				)}
			/>
	);
}

import Eliza from "components/ui/Eliza";
import LogoOCB from "components/ui/LogoOCB";
import VolumeControl from "components/ui/VolumeControl";
import { CSSProperties, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import LoginForm from "./components/LoginForm";
import { useAuth } from "context/AuthContext";

export function Login() {
	const location = useLocation();
	const { checkGameIsEnabled } = useAuth();
	const [showForm, setShowForm] = useState(false);

	useEffect(() => {
		checkGameIsEnabled();
	}, []);

	useEffect(() => {
		// console.log(location.state);
		const locationState: any = location.state;
		if (locationState && locationState.success) {
			setShowForm(true);
		}
	}, [location]);

	return (
		<>
			<div className="absolute z-30 top-10 right-10">
				{/* <VolumeControl /> */}
			</div>

			<div id="login" className="relative w-full h-full">
				<LoginForm />
				<LogoOCB white className="logo-ocb" />
			</div>
		</>
	);
}

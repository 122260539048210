import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import frame from "assets/images/frame.svg";
import logo from "assets/images/logo-desempenho.svg";
import Eliza from "components/ui/Eliza";

import iconCredito from "assets/images/icon-credito.svg";
import iconSaude from "assets/images/icon-saude.svg";
import iconDemais from "assets/images/icon-demais.svg";
import Decoration from "components/ui/Decoration";
import Curve from "components/ui/Curve";

import "./styles.scss";
import { useDialog } from "context/DialogContext";
import AudioStep4 from "components/audio/AudioStep4";
import Step4Desempenho from "./desempenho";
import Step4Identidade from "./identidade";

const frameStyle: CSSProperties = {
	background: `url(${frame}) no-repeat center`,
	backgroundSize: "contain",
};

const icons: any = {
	credito: iconCredito,
	saude: iconSaude,
	demais: iconDemais,
};

export default function Step4() {
	const { coop, coopInfo, categoria } = useCoop();
	const [tipo, setTipo] = useState("");

	useEffect(() => {
		if (!coopInfo) return;
		const ramo: string = coopInfo.ramo.toLowerCase();
		if (ramo.includes("trabalho")) {
			setTipo("identidade");
		} else {
			setTipo("desempenho");
		}
	}, [coop, coopInfo]);

	if (!coopInfo) return null;

	if (tipo === "desempenho") {
		return <Step4Desempenho />;
	} else if (tipo === "identidade") {
		return <Step4Identidade />;
	}

	return null;
}

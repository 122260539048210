import { UIElementProps } from "types/prop-types";

import sad from "assets/images/icon-emoji-sad.svg";
import love from "assets/images/icon-emoji-love.svg";

const Sources: any = {
	default: sad,
	sad,
	love,
};

export default function Icon({ name, className, style }: UIElementProps) {
	return (
		<img
			src={Sources[name || "default"]}
			className={`pointer-none ${className}`}
			style={style}
		/>
	);
}

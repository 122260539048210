import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import Curve from "components/ui/Curve";
import Decoration from "components/ui/Decoration";
import Eliza from "components/ui/Eliza";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: "top" as const,
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
};

const labels = ["2019", "2020", "2021"];

import "./styles.scss";
import { useDialog } from "context/DialogContext";
import AudioStep7 from "components/audio/AudioStep7";
import Step7Identidade from "./identidade";
import Step7Desempenho from "./desempenho";
import { useLocation, useNavigate } from "react-router-dom";
import Step5Credito from "../Step5/credito";
import Step5Demais from "../Step5/demais";
import Step5Saude from "../Step5/saude";
// const data = {
// 	labels,
// 	datasets: [
// 		// {
// 		//   label: '',
// 		//   data: labels.map(() => faker.datatype.number({ min: 0, max: 20 })),
// 		//   borderColor: 'rgb(255, 99, 132)',
// 		//   backgroundColor: 'rgba(255, 99, 132, 0.5)',
// 		// },
// 		// {
// 		//   label: '',
// 		//   data: labels.map(() => faker.datatype.number({ min: 0, max: 20 })),
// 		//   borderColor: 'rgb(53, 162, 235)',
// 		//   backgroundColor: 'rgba(53, 162, 235, 0.5)',
// 		// },
// 		// {
// 		//   label: '',
// 		//   data: labels.map(() => faker.datatype.number({ min: 0, max: 20 })),
// 		//   borderColor: 'rgb(153, 62, 235)',
// 		//   backgroundColor: 'rgba(153, 62, 235, 0.5)',
// 		// },
// 	],
// };\

const dashboard = require("assets/images/dashboard-desempenho.jpg");

export default function Step7() {
	const { coop, categoria } = useCoop();

	const location = useLocation();
	const navigate = useNavigate();

	// const { showDialog } = useDialog();

	useEffect(() => {
		try {
			if(coop) return;

			const locationState: any = location.state;
			if (locationState && locationState.direction) {
				if (locationState.direction === "forward") {
					navigate("/passo/7");
				} else if (locationState.direction === "back") {
					navigate("/passo/5");
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [coop, categoria, location]);

	useEffect(()=>{
		document.querySelector("body")?.classList.add("desempenho");

		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("desempenho");
		}
	},[]);

	if (coop) {
		if (categoria === "identidade") {
			return <Step7Identidade />;
		} else {
			if (categoria === "credito") {
				return <Step5Credito />;
			} else if (categoria === "saude") {
				return <Step5Saude />;
			} else if (categoria === "demais") {
				return <Step5Demais />;
			}
		}
	}

	return null;
}

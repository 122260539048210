import Eliza from "components/ui/Eliza";
import { useCoop } from "context/CoopContext";
import { useEffect, useState } from "react";

import QrCode from "assets/images/qrcode-anuario.svg";

const anuario = require("assets/images/anuario.jpg");

import "./styles.scss";
import Shape from "components/ui/Shape";
import Curve from "components/ui/Curve";
import { useDialog } from "context/DialogContext";
import AudioStep3 from "components/audio/AudioStep3";

export default function Step3() {
	const { showDialog } = useDialog();

	useEffect(() => {
		document.querySelector("body")?.classList.add("soucoop");

		showDialog(
			"Mantendo as informações atualizadas, você nos ajuda a defender e divulgar o coop com dados mais precisos sobre o nosso movimento!"
		);

		return () => {
			document.querySelector("body")?.classList.remove("soucoop");
		};
	}, []);

	return (
		<>
			<AudioStep3 />
			<div id="step3">
				<div className="anuario -rotate-[22deg]">
					<img src={anuario} />
				</div>
				<Shape name="white" className="shape" />
				<Eliza name="shipRight" className="eliza animate animate-fly" />

				<div className="qrcode">
					<div className="flex flex-col items-center justify-center">
						<div className="text-center text-white font-bold text-2xl leading-none mb-4">
							Acesse o site
							<br />
							do Anuário
						</div>
						<div className="w-[68%] mx-auto mb-5">
							<img src={QrCode} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

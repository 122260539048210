import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect } from "react";

import frame from "assets/images/frame.svg";
import logo from "assets/images/logo-desempenho.png";
import Eliza from "components/ui/Eliza";

import iconCredito from "assets/images/icon-credito.svg";
import iconSaude from "assets/images/icon-saude.svg";
import iconDemais from "assets/images/icon-demais.svg";
import Decoration from "components/ui/Decoration";
import Curve from "components/ui/Curve";

import "./styles.scss";
import { useDialog } from "context/DialogContext";
import AudioStep4 from "components/audio/AudioStep4";
import Button from "components/ui/Button";
import usePointsStore from "store";

const frameStyle: CSSProperties = {
	background: `url(${frame}) no-repeat center`,
	backgroundSize: "contain",
};

const icons: any = {
	credito: iconCredito,
	saude: iconSaude,
	demais: iconDemais,
};

export default function Step8GameDesempenho() {
	const {
		displayPointsNotification,
		setPoints,
		setPontosGameGovernanca,
		toggleNavigation,
	} = usePointsStore();

	function onMessage({ data }: any) {
		if (data.type === "game-finished") {
			toggleNavigation(true);
			console.log(data.score);
			if (data.score === 3) {
				displayPointsNotification(500);
				setPontosGameGovernanca(500);
			}
		}
	}

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, []);

	return (
		<>
			<div id="step8game" className="relative w-full h-full">
				<iframe
					src="/games/maze/index.html"
					className="absolute border w-full h-full"
				/>
			</div>
		</>
	);
}

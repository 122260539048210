import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import { H1 } from "components/typography";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

import Eliza from "components/ui/Eliza";
import Selo from "./components/selo";
import SeloPlaceholder from "./components/selo-placeholder";

import seloImage from "assets/images/selo.svg";

import "./styles.scss";
import Shape from "components/ui/Shape";
import Decoration from "components/ui/Decoration";
import { useDialog } from "context/DialogContext";
import AudioStep16 from "components/audio/AudioStep16";
import AudioStep17Nao from "components/audio/AudioStep17Nao";
import Fireworks from "components/ui/Fireworks";
import { Draggable } from "react-touch";
import AudioStep17 from "components/audio/AudioStep17";
import AudioStep17Sim from "components/audio/AudioStep17Sim";

export default function Step17() {
	const { coop, setSeloCoop } = useCoop();
	const [hoverElement, setHoverElement] = useState<any | null>(null);

	const [seloSelected, setSeloSelected] = useState(false);
	const [possuiSelo, setPossuiSelo] = useState(false);

	const { showDialog, closeDialog } = useDialog();

	function handleDrop(value: boolean) {
		console.log("handleDrop", value);
		setSeloSelected(true);
		setPossuiSelo(value);

		if (value) {
			setSeloCoop(true);
			closeDialog();
			showDialog([
				"Muito bem! Juntos vamos levar o cooperativismo ainda mais longe!",
			]);
		} else {
			setSeloCoop(false);
			closeDialog();
			showDialog([
				"Acesse somos.coop.br e conheça o nosso movimento feito para levar o cooperativismo ainda mais longe!",
			]);
		}
	}

	useEffect(() => {
		if (seloSelected && !possuiSelo) {
			// document.querySelector("body")?.classList.add("danger");
		} else if (seloSelected && possuiSelo) {
			// document.querySelector("body")?.classList.remove("danger");
		}
	}, [seloSelected, possuiSelo]);

	useEffect(() => {
		showDialog([
			"Muito legal, não é mesmo? Se a sua coop ainda não utiliza o carimbo, acesse: somos.coop.br e veja como começar. Juntos vamos levar o cooperativismo ainda mais longe.",
		]);

		document.querySelector("body")?.classList.add("somoscoop");

		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("somoscoop");
		};
	}, []);

	// if (seloSelected) {
	// 	return (
	// 		<div id="step17">
	// 			{possuiSelo && (
	// 				<>
	// 				<AudioStep17Sim />
	// 				<Fireworks />
	// 					<div className="text">
	// 						<h1 className="text-white text-9xl">Parabéns</h1>
	// 						<p className="text-white text-7xl mb-6">
	// 							Sua coop utiliza o <br />carimbo SomosCoop.
	// 						</p>
	// 						<img src={seloImage} alt="Selo" className="w-64" />
	// 					</div>
	// 					<Eliza name="shipHappy" className="eliza-happy animate-fly" />
	// 					<Decoration name="hands" className="hands duration-500 animate-in slide-in-from-bottom" />
	// 					{/* <Shape name="white3" className="shape" /> */}
	// 				</>
	// 			)}

	// 			{!possuiSelo && (
	// 				<>
	// 				<AudioStep17Nao />
	// 				<div className="text">
	// 					<h1 className="text-white text-9xl">Que pena!</h1>
	// 					<p className="text-white text-5xl mt-3 border-4 p-3 border-blue-500">
	// 							Você ainda não utiliza <br /> o carimbo SomosCoop.
	// 						</p>
	// 						</div>

	// 						<div className="selo-down w-64"><img src={seloImage} alt="Selo" className="animate-wiggle" /></div>

	// 					<Eliza name="sad" className="eliza-sad animate-wiggle" />
	// 				</>
	// 			)}
	// 		</div>
	// 	);
	// }

	return (
		<>
			{/* <AudioStep17 /> */}
			<div id="step17">
				{/* <div className="title text-center">
					<H1 className="font-bold text-6xl text-white">
						Sua coop utiliza o carimbo SomosCoop?
					</H1>
					<p className="text-4xl text-white mt-16 text-white animate-bounce">
						Clique na tela e selecione a opção desejada
					</p>
				</div> */}

				<Eliza name="shipHappy" className="eliza-happy animate-fly" />

				{/* <Decoration
					name="hands"
					className="hands duration-500 animate-in slide-in-from-bottom"
				/> */}

				<div className="selo-image animate-in slide-in-from-bottom">
					<img src={seloImage} alt="Selo" className="object-contain" />
				</div>

				{/* <Shape name="white3" className="shape" /> */}

				{/* <div className="selo flex flex-row items-center justify-center text-white">
				
				<div className="border border-dashed rounded-full h-36 flex items-center justify-center w-64 text-4xl">
					NÃO
				</div>

				<div onClick={()=>handleDrop(false)}>
				<SeloPlaceholder
					isHover={hoverElement === false}
					onHover={(e) => setHoverElement(e)}
					onDrop={handleDrop}
					value={false}
				>
					NÃO
				</SeloPlaceholder>
				</div>

				<div>
					<BiLeftArrowAlt size={52} />
				</div>

				<div className="cursor-pointer rounded-full w-64 h-36 animated animate-wiggle overflow-hidden">
					<img src={selo} alt="Selo" className="object-contain" />
				</div>

				<Selo onStopDragging={() => setHoverElement(null)} />

				<div>
					<BiRightArrowAlt size={52} />
				</div>

				<div onClick={()=>handleDrop(true)}>
					<SeloPlaceholder
						isHover={hoverElement === true}
						onHover={(e) => setHoverElement(e)}
						onDrop={handleDrop}
						value={true}
					>
						SIM
					</SeloPlaceholder>
				</div>
			</div> */}

				{/* <Draggable style={{translateX: 150, translateY: 200}}>
  {({translateX, translateY}) => {
    return (
      <div style={{transform: `translate3d(${translateX}px, ${translateY}px, 0)`}}>
       <div className="w-96 h-40 bg-black">OPA</div>
      </div>
    );
  }}
</Draggable> */}

				{/* <Eliza name="stars" className="eliza pointer-events-none" /> */}
			</div>
		</>
	);
}

import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import peoplePuzzle from "assets/images/selo.svg";

import "./styles.scss";
import Shape from "components/ui/Shape";
import { useDialog } from "context/DialogContext";
import AudioStep15 from "components/audio/AudioStep15";
import Step15Game from "./game";
import { useNavigate } from "react-router-dom";
import Button from "components/ui/Button";
import Decoration from "components/ui/Decoration";
import Eliza from "components/ui/Eliza";
import usePointsStore from "store";

export default function Step15() {
	const navigate = useNavigate();
	const { showDialog, closeDialog } = useDialog();
	const [displayGame, setDisplayGame] = useState(false);

	const [bonus, setBonus] = useState(100);
	const [moedas, setMoedas] = useState(0);

	function playGame() {
		closeDialog();
		setDisplayGame(true);
	}

	function next() {
		navigate("/passo/11");
	}

	useEffect(() => {
		document.querySelector("body")?.classList.add("somoscoop");
		showDialog([
			"Para explicar o cooperativismo para sociedade, lançamos o movimento SomosCoop, com direito a um carimbo para incentivar a escolha de produtos e serviços coop.",
		]);
		return () => {
			document.querySelector("body")?.classList.remove("somoscoop");
		};
	}, []);

	return (
		<>
			{/* <AudioStep15 /> */}
			<div id="step15">
				{displayGame ? (
					<>
						<Decoration name="monitor" className="monitor-game" />
						<div className="game">
							<Step15Game />
						</div>
					</>
				) : (
					<>
						<img
							src={peoplePuzzle}
							className="logo duration-1000 animate-in zoom-in"
						/>
						<Decoration name="monitor" className="monitor" />
						<Eliza
							name="home"
							className="eliza animate-in slide-in-from-bottom"
						/>
						<div className="buttons w-[650px] space-y-4 text-center border-t-2 pt-6">
							<div className="text-[45px]">Vamos jogar?</div>
							<div className="flex gap-4">
								<Button
									onClick={() => playGame()}
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									SIM
								</Button>
								<Button
									onClick={() => next()}
									color="danger"
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									PULAR
								</Button>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

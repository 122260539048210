import AudioStep10 from "components/audio/AudioStep10";
import Eliza from "components/ui/Eliza";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.scss";

const identidadeNacional = 69;
const gestaoNacional = 49.2;

export default function Step7Identidade() {
	const { coop, coopInfo, categoria } = useCoop();
	const navigate = useNavigate();

	const [identidade, setIdentidade] = useState(0);
	const [gestao, setGestao] = useState(0);

	const location = useLocation();
	const { showDialog } = useDialog();

	useEffect(() => {
		try {
			if (coop && coop.length > 0) {
				setIdentidade(coop[0].indicador);
				setGestao(coop[0].gestao);
				showDialog(
					"Por meio da autoavaliação das práticas de gestão e aderência aos princípios cooperativistas, você acompanha a evolução da sua coop no índice do Programa Identidade!"
				);
			} else {
				const locationState: any = location.state;
				if (locationState && locationState.direction) {
					if (locationState.direction === "forward") {
						navigate("/passo/7");
					} else if (locationState.direction === "back") {
						navigate("/passo/5");
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [coop, location]);

	useEffect(() => {
		document.querySelector("body")?.classList.add("identidade");
		return () => {
			document.querySelector("body")?.classList.remove("identidade");
		};
	}, []);

	if (!coop || !coop.length) return null;

	return (
		<>
			{/* <AudioStep10 /> */}
			<div id="step10-identidade">
				<div className="title text-white text-6xl slide-in slide-in-bottom">
					Confira os índices da sua coop.
				</div>

				<div className="governanca">
					<div className="text-white">
						<h1 className="text-6xl text-green-900">PRINCÍPIOS</h1>
						<p className="text-9xl">{identidade}%</p>
					</div>
					<div className="mt-10">
						<div className="relative rounded-xl w-40 h-96 bg-purple-800 flex flex-col justify-end overflow-hidden">
							<div
								className={`absolute z-20 progress w-full border-t-4 border-t-black border-dashed font-bold text-xl pt-3 leading-none`}
								style={{ height: `${identidadeNacional}%` }}
							>
								<div className="bg-white bg-opacity-80 mx-2 py-2 rounded-md text-gray-700 shadow-lg">
									{identidadeNacional}% NACIONAL
								</div>
							</div>
							<div
								className={`absolute progress w-full bg-yellow-500 border-t-4 border-t-white`}
								style={{ height: `${identidade}%` }}
							></div>
						</div>
					</div>
				</div>

				<div className="gestao">
					<div className="text-white">
						<h1 className="text-6xl text-red-900">GESTÃO</h1>
						<p className="text-9xl">{gestao}%</p>
					</div>
					<div className="mt-10">
						<div className="relative rounded-xl w-40 h-96 bg-purple-800 flex flex-col justify-end overflow-hidden">
							<div
								className={`absolute z-20 progress w-full border-t-4 border-t-black border-dashed font-bold text-xl pt-3 leading-none`}
								style={{ height: `${gestaoNacional}%` }}
							>
								<div className="bg-white bg-opacity-80 mx-2 py-2 rounded-md text-gray-700 shadow-lg">
									{gestaoNacional}% NACIONAL
								</div>
							</div>
							<div
								className={`absolute progress w-full bg-yellow-500 border-t-4 border-t-white`}
								style={{ height: `${gestao}%` }}
							></div>
						</div>
					</div>
				</div>

				<Eliza name="stars" className="eliza" />
			</div>
		</>
	);
}

import AudioStep2 from "components/audio/AudioStep2";
import Curve from "components/ui/Curve";
import Eliza from "components/ui/Eliza";
import Shape from "components/ui/Shape";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect, useState } from "react";

import "./styles.scss";
import Step2Game from "./game";
import { Step } from "..";
import Step2TimelineB from "./timeline_b";
import Step2TimelineA from "./timeline_a";
import Step2TimelineC from "./timeline_c";

export default function Step2() {
	const [view, setView] = useState("game");

	return (
		<>
			{view === "game" ? (
				<Step2Game onFinish={(answer: string) => setView(answer)} />
			) : null}
			{view === "timeline_a" ? <Step2TimelineA /> : null}
			{view === "timeline_b" ? <Step2TimelineB /> : null}
			{view === "timeline_c" ? <Step2TimelineC /> : null}
		</>
	);
}

import { CSSProperties, useEffect } from "react";

import frame from "assets/images/frame.svg";

import iconCredito from "assets/images/icon-credito.svg";
import iconSaude from "assets/images/icon-saude.svg";
import iconDemais from "assets/images/icon-demais.svg";

import "./styles.scss";
import usePointsStore from "store";

const frameStyle: CSSProperties = {
	background: `url(${frame}) no-repeat center`,
	backgroundSize: "contain",
};

const icons: any = {
	credito: iconCredito,
	saude: iconSaude,
	demais: iconDemais,
};

export default function Step4GameDesempenho() {
	const {
		displayPointsNotification,
		setPoints,
		setPontosGameDesempenho,
		toggleNavigation,
	} = usePointsStore();

	function onMessage({ data }: any) {
		if (data.type === "game-finished") {
			toggleNavigation(true);
			displayPointsNotification(data.score);
			setPontosGameDesempenho(data.score);
		}
	}

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, []);

	return (
		<>
			<div id="step4game" className="relative w-full h-full">
				<iframe
					src="/games/coins/index.html"
					className="absolute border w-full h-full"
				/>
			</div>
		</>
	);
}

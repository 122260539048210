import AudioStep6Nao from "components/audio/AudioStep6Nao";
import Eliza from "components/ui/Eliza";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { CSSProperties, useEffect } from "react";
import Step5Credito from "./credito";
import Step5Demais from "./demais";
import Step5Desempenho from "./desempenho";
import Step5Identidade from "./identidade";
import Step5Saude from "./saude";

export default function Step5() {
	const { coop, coopInfo, categoria } = useCoop();
	const { showDialog } = useDialog();

	useEffect(() => {
		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("identidade");
			document.querySelector("body")?.classList.remove("desempenho");
		};
	}, []);

	useEffect(() => {
		if (!coopInfo) return;

		console.log(categoria);

		if (categoria === "identidade") {
			document.querySelector("body")?.classList.add("identidade");
		} else {
			document.querySelector("body")?.classList.add("desempenho");
		}

		if (!coop) {
			// document.querySelector("body")?.classList.add("danger");
			if (categoria !== "identidade") {
				showDialog(
					`Poxa! Vi que a ${coopInfo?.nome_fantasia} não está no Sistema Desempenho! Aproveite tudo o que o Sistema Desempenho pode oferecer para a sua coop. Fale com a gente!`
				);
			}
		} else {
			document.querySelector("body")?.classList.remove("danger");
			if (categoria === "identidade") {
				document.querySelector("body")?.classList.add("identidade");
			} else {
				document.querySelector("body")?.classList.add("desempenho");
			}
		}
	}, [coop, coopInfo, categoria]);

	if (categoria !== "identidade") {
		if (!coop) {
			return (
				<>
					<AudioStep6Nao />
					<div id="step1">
						<div className="text-center absolute top-32 right-0 left-0 mx-auto w-1/2 h-1/2 flex flex-col items-center justify-center">
							<h1 className="text-white text-9xl">Que pena!</h1>
							<p className="text-white text-5xl mt-3 border-4 p-3 border-blue-500">
								Sua coop não participa <br />
								do Sistema Desempenho
							</p>
						</div>
						<Eliza name="sad" className="eliza-sad animate animate-wiggle" />
					</div>
				</>
			);
		}

		return <Step5Desempenho />;
	} else {
		return <Step5Identidade />;
	}

	return null;
}

import React, { ReactNode, useContext } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { validateUserPermissions } from "../utils/validateUserPermissions";
import { useAuth } from "context/AuthContext";
import { Locked } from "pages/Locked";

interface IPrivateRoute {
	permissions?: string[];
	roles?: string[];
	redirectTo?: string;
	children: ReactNode;
}

export function PrivateRoute({
	permissions,
	roles,
	redirectTo = "/",
	children,
}: IPrivateRoute) {
	const { isAuthenticated, isGameEnabled, checkGameIsEnabled } = useAuth();

	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1024px)",
	});

	const isLargeDesktop = useMediaQuery({
		query: "(min-width: 1440px)",
	});

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const navigate = useNavigate();

	if (!isGameEnabled && location.pathname !== "/locked") {
		navigate("/locked");
	} else if (!isAuthenticated) {
		return <Navigate to={redirectTo} />;
	}

	// if (!hasAllPermissions) {
	// 	return <Navigate to="/" />;
	// }

	return <>{children}</>;
}
function useEffect(arg0: () => void, arg1: (string | boolean)[]) {
	throw new Error("Function not implemented.");
}

import { H1 } from "components/typography";
import Eliza from "components/ui/Eliza";
import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";
import { BiMapPin, BiPin, BiSearchAlt } from "react-icons/bi";
import { FaChartPie, FaMapMarkerAlt, FaMapSigns } from "react-icons/fa";

import "./styles.scss";

import iconPdgc from "assets/images/pdgc.svg";
import pdgcElements from "assets/images/pdgc-elements.svg";
import logoAvalia from "assets/images/logo-governanca.png";

import Curve from "components/ui/Curve";
import { useDialog } from "context/DialogContext";
import AudioStep8 from "components/audio/AudioStep8";
import Button from "components/ui/Button";
import Decoration from "components/ui/Decoration";
import Step8GameDesempenho from "./game";
import { useNavigate } from "react-router-dom";

export default function Step8() {
	const navigate = useNavigate();
	const { showDialog, closeDialog } = useDialog();

	const [bonus, setBonus] = useState(100);
	const [moedas, setMoedas] = useState(0);
	const [displayGame, setDisplayGame] = useState(false);

	function playGame() {
		closeDialog();
		setDisplayGame(true);
	}

	function next() {
		navigate("/passo/8");
	}

	useEffect(() => {
		document.querySelector("body")?.classList.add("pdgc");
		showDialog(
			"E como andam a Governança e a Gestão da sua Coop? Agora vamos falar um pouco sobre o Governança e Gestão AvaliaCoop"
		);
		return () => {
			document.querySelector("body")?.classList.remove("pdgc");
		};
	}, []);

	return (
		<>
			<AudioStep8 />
			<div id="step8">
				{displayGame ? (
					<>
						<Decoration name="monitor" className="monitor-game" />
						<div className="game">
							<Step8GameDesempenho />
						</div>
					</>
				) : (
					<>
						<img
							src={logoAvalia}
							className="logo duration-1000 animate-in zoom-in"
						/>
						<Decoration name="monitor" className="monitor" />
						{/* <Eliza
							name="home"
							className="eliza animate-in slide-in-from-bottom"
						/> */}
						<div className="buttons w-[650px] space-y-4 text-center border-t-2 pt-6">
							<div className="text-[45px]">Vamos jogar?</div>
							<div className="flex gap-4">
								<Button
									onClick={() => playGame()}
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									SIM
								</Button>
								<Button
									onClick={() => next()}
									color="danger"
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									PULAR
								</Button>
							</div>
						</div>

						{/* <img
							src={iconPdgc}
							alt="iconPdgc"
							className="iconPdgc animate animate-spin-slow"
						/> */}

						{/* <Curve className="curve" /> */}

						<Eliza
							name="home"
							className="eliza animate-in slide-in-from-bottom"
						/>
					</>
				)}
			</div>
		</>
	);
}

import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { RouteList } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { ModalProvider } from "context/ModalContext";
import ToastProvider from "context/ToastContext";
import { CoopProvider } from "context/CoopContext";
import { ViewportProvider } from "context/ViewportContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DialogProvider } from "context/DialogContext";

import { isMobile } from 'react-device-detect';

const App = () => (
	<ViewportProvider>
		<BrowserRouter>
			<AuthProvider>
				<CoopProvider>
					<ModalProvider>
						<DialogProvider>
							<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
								<RouteList />
							</DndProvider>
							<ToastProvider />
						</DialogProvider>
					</ModalProvider>
				</CoopProvider>
			</AuthProvider>
		</BrowserRouter>
	</ViewportProvider>
);

export default App;

// import headbreaker from 'headbreaker';

import { useEffect } from "react";
import usePointsStore from "store";

export default function Step15Game() {
	const {
		displayPointsNotification,
		setPoints,
		setPontosGameSomosCoop,
		toggleNavigation,
	} = usePointsStore();

	function onMessage({ data }: any) {
		if (data.type === "game-finished") {
			toggleNavigation(true);
			if (data.score === 6) {
				displayPointsNotification(500);
				setPontosGameSomosCoop(500);
			}
		}
	}

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
		};
	}, []);

	return (
		<>
			<div id="step15game" className="relative w-full h-full">
				<iframe
					src="/games/puzzle/index.html"
					className="absolute border w-full h-full"
				/>
			</div>
		</>
	);
}

import { create } from "zustand";

type PointsStore = {
	points: number;
	souCoop: boolean;
	desempenhoCoop: boolean;
	governancaCoop: boolean;
	pontosGameDesempenho: number;
	pontosGameGovernanca: number;
	pontosGameSomosCoop: number;
	pontosGameQuiz: number;
	showPoints: boolean;
	isNavigationVisible: boolean;
	isGameEnabled: boolean;
	isLotteryEnabled: boolean;
	toggleNavigation: (visible: boolean) => void;
	displayPointsNotification: (score?: number) => void;
	hidePointsNotification: () => void;
	setDesempenhoCoop: (score: boolean) => void;
	setGovernancaCoop: (score: boolean) => void;
	setSouCoop: (score: boolean) => void;
	setPontosGameDesempenho: (score: number) => void;
	setPontosGameGovernanca: (score: number) => void;
	setPontosGameSomosCoop: (score: number) => void;
	setPontosGameQuiz: (score: number) => void;
	setPoints: (score: number) => void;
	resetPointsStore: () => void;
};

const usePointsStore = create<PointsStore>((set) => ({
	points: 1000,
	showPoints: false,
	souCoop: false,
	desempenhoCoop: false,
	governancaCoop: false,
	pontosGameDesempenho: 0,
	pontosGameGovernanca: 0,
	pontosGameSomosCoop: 0,
	pontosGameQuiz: 0,
	isNavigationVisible: true,
	isGameEnabled: true,
	isLotteryEnabled: true,
	toggleNavigation: (visible) => {
		set({ isNavigationVisible: visible });
	},
	displayPointsNotification: (score = 1000) => {
		set({ points: score, showPoints: true });
	},
	hidePointsNotification: () => {
		set({ showPoints: false });
	},
	setDesempenhoCoop: (score: boolean) => {
		set({ desempenhoCoop: score });
	},
	setGovernancaCoop: (score: boolean) => {
		set({ governancaCoop: score });
	},
	setSouCoop: (score: boolean) => {
		set({ souCoop: score });
	},
	setPontosGameDesempenho: (score: number) => {
		set({ pontosGameDesempenho: score });
	},
	setPontosGameGovernanca: (score: number) => {
		set({ pontosGameGovernanca: score });
	},
	setPontosGameSomosCoop: (score: number) => {
		set({ pontosGameSomosCoop: score });
	},
	setPontosGameQuiz: (score: number) => {
		set({ pontosGameQuiz: score });
	},
	setPoints: (score: number) => {
		set({ points: score });
	},
	resetPointsStore: () => {
		set({
			points: 1000,
			souCoop: false,
			desempenhoCoop: false,
			governancaCoop: false,
			pontosGameDesempenho: 0,
			pontosGameGovernanca: 0,
			pontosGameSomosCoop: 0,
			pontosGameQuiz: 0,
			showPoints: false,
		});
	},
}));

export default usePointsStore;

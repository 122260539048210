import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import frame from "assets/images/frame.svg";
import logo from "assets/images/logo-desempenho.png";
import Eliza from "components/ui/Eliza";

import iconCredito from "assets/images/icon-credito.svg";
import iconSaude from "assets/images/icon-saude.svg";
import iconDemais from "assets/images/icon-demais.svg";
import Decoration from "components/ui/Decoration";
import Curve from "components/ui/Curve";

import "./styles.scss";
import { useDialog } from "context/DialogContext";
import AudioStep4 from "components/audio/AudioStep4";
import Button from "components/ui/Button";
import { useNavigate } from "react-router-dom";
import Step4GameDesempenho from "./game_desempenho";

const frameStyle: CSSProperties = {
	background: `url(${frame}) no-repeat center`,
	backgroundSize: "contain",
};

const icons: any = {
	credito: iconCredito,
	saude: iconSaude,
	demais: iconDemais,
};

export default function Step4Desempenho() {
	const { showDialog, closeDialog } = useDialog();
	const navigate = useNavigate();

	const [bonus, setBonus] = useState(100);
	const [moedas, setMoedas] = useState(0);
	const [displayGame, setDisplayGame] = useState(false);

	function playGame() {
		closeDialog();
		setDisplayGame(true);
	}

	function next() {
		navigate("/passo/5");
	}

	useEffect(() => {
		document.querySelector("body")?.classList.add("desempenho");

		showDialog(
			"Agora vamos ver como está a saúde financeira da sua coop. Neste desafio você deve pegar todas as moedas que aparecem na tela. Você está pronto?"
		);

		return () => {
			document.querySelector("body")?.classList.remove("desempenho");
		};
	}, []);

	return (
		<>
			<div id="step4">
				<Curve className="curve" />

				{displayGame ? (
					<>
						<Decoration name="monitor" className="monitor-game" />
						<div className="game">
							<Step4GameDesempenho />
						</div>
					</>
				) : (
					<>
						<img src={logo} className="logo duration-1000 animate-in zoom-in" />
						<Decoration name="monitor" className="monitor" />
						<Eliza
							name="home"
							className="eliza animate-in slide-in-from-bottom"
						/>
						<div className="buttons w-[650px] space-y-4 text-center border-t-2 pt-6">
							<div className="text-[45px]">Vamos jogar?</div>
							<div className="flex gap-4">
								<Button
									onClick={() => playGame()}
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									SIM
								</Button>
								<Button
									onClick={() => next()}
									color="danger"
									className="flex-1 py-4 px-6 leading-none text-[45px]"
								>
									PULAR
								</Button>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

import { CSSProperties } from "react";
import { UIElementProps } from "types/prop-types";

const CurveStyle: CSSProperties = {
	fill: "none",
	stroke: "#fff",
	strokeMiterlimit: 10,
	strokeWidth: "3px"
};

export default function Curve({ className }: UIElementProps) {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 2062.17 847.28"
			className={className}
		>
			<g>
				<path
					style={CurveStyle}
					d="M2060.74,.44c-5.85,19-11.84,38.14-20.21,57.28-1.2,2.39,0,3.59-1.2,5.98-16.75,37.09-32.3,70.58-56.23,105.28-21.53,31.1-41.87,57.42-66.99,83.74-8.37,7.18-14.36,15.55-22.73,22.73-74.17,69.39-160.31,117.24-257.21,145.95-7.18,2.39-15.55,3.59-22.73,5.98-100.49,25.12-198.59,21.53-299.08-3.59-1.2,0-3.59-2.39-4.79-2.39-3.59-1.2-8.37-1.2-11.96-2.39-26.32-8.37-51.44-16.75-76.56-28.71-131.6-62.21-258.41-124.42-390-186.63-2.39-1.2-3.59,0-5.98-1.2-186.63-81.35-400.77-63.41-576.63,55.03-95.71,65.8-167.49,147.15-211.75,250.03-14.36,34.69-23.93,69.39-29.91,107.67-11.96,80.15-3.59,157.91,25.25,231.53"
				/>
			</g>
		</svg>
	);
}

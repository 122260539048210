import { useEffect } from "react";
// import { AuthContext } from "context/AuthContext";

export function NotFound() {

	useEffect(() => {}, []);

	return (
		<div className="pb-10">
			<div className="bg-purple rounded-bl-xl">
			<div className="flex flex-col items-center text-xl md:text-2xl py-12 pb-40 md:py-16">
			<div className="font-bold text-white text-xl md:text-3xl">
				Página <strong className="text-yellow">não encontrada</strong>
			</div>
		</div>
			</div>
		</div>
	);
}

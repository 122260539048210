import { useDialog } from "context/DialogContext";
import { useEffect } from "react";
import useSound from "use-sound";

const audioStep = require("assets/audio/step5.webm");

export default function AudioStep5() {
	const {isMuted} = useDialog();
	const [voiceOver, { stop }] = useSound(audioStep, {
		id: "step5",
		volume: 0.5,
	});

	useEffect(() => {
		if (!isMuted) {
			voiceOver();
		}
		else{
			stop();
		}
		return () => stop();
	}, [isMuted,voiceOver]);

	return null;
}

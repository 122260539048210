import { UIElementProps } from "types/prop-types";

import hands from "assets/images/icon-hands.svg";
import monitor from "assets/images/monitor.svg";

const Sources : any = {
	hands,
	monitor
}

export default function Decoration({name, className}:UIElementProps){
	return <img src={Sources[name]} className={`pointer-none ${className}`} />
}

import { useCoop } from "context/CoopContext";
import { CSSProperties, useEffect, useState } from "react";

import trophy from "assets/images/trophy.svg";
import { formatarFaturamento } from "utils/helpers";

import "./styles.scss";
import Shape from "components/ui/Shape";
import { H2 } from "components/typography";
import { useDialog } from "context/DialogContext";
import AudioStep5 from "components/audio/AudioStep5";

let formatter = Intl.NumberFormat("pt-br", { notation: "compact" });

export default function Step5Credito() {
	const { coop, coopInfo, ranking, categoria } = useCoop();
	const { showDialog } = useDialog();
	const [faturamento, setFaturamento] = useState("");

	useEffect(() => {
		if (ranking) {
			setFaturamento(formatarFaturamento(ranking.total));
		}
	}, [ranking]);

	useEffect(() => {
		if (coopInfo && faturamento) {
			showDialog(
				`A coop ${coopInfo.nome_fantasia} possui R$ ${faturamento} em ativos totais. Uau! `
			);
		}
	}, [coopInfo, faturamento]);

	if (!coop || !categoria) return null;

	return (
		<>
			<AudioStep5 />
			<div id="step5">
				<div className="flex flex-row">
					<img src={trophy} alt="trophy" className="trophy" />
					<div className="content">
						<h1 className="text-6xl font-bold text-center text-white">
							Confira o desempenho da
							<br />
							sua coop no ramo crédito
							<br />
							em São Paulo:
						</h1>
						<h1 className="text-8xl font-bold text-center text-yellow-500 mt-4">
							{ranking?.position}º
						</h1>
						<div className="text-8xl font-bold text-center text-black mt-24 animate animate-wiggle">
							<p className="text-8xl">R$ {faturamento}</p> em ativos totais
						</div>
					</div>
					<Shape name="white3" className="shape" />
				</div>
			</div>
		</>
	);
}

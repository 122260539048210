import { getISODay, addDays } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export function getClosestDaysOfNextWeek(
	dayOfWeek: number,
	fromDate = new Date()
) {
	// Mon: 1, Tue: 2, Wed: 3, Thur: 4, Fri: 5, Sat: 6, Sun: 7
	const dayOfWeekMap = [0, 1, 2, 3, 4, 5, 6];

	// Converte hora local para PST
	const zonedFrom = utcToZonedTime(
		fromDate.toISOString(),
		`America/Los_Angeles`
	);

	// Horário local da máquina do usuario
	const localFrom = zonedTimeToUtc(zonedFrom, `America/Los_Angeles`);

	// Horário do Brasil
	const brazilFrom = utcToZonedTime(
		fromDate.toISOString(),
		`America/Sao_Paulo`
	);

	const offsetDaysToFirstDate =
		7 - (getISODay(localFrom) - dayOfWeekMap[dayOfWeek]);
	const offsetDaysToSecondDate =
		14 - (getISODay(localFrom) - dayOfWeekMap[dayOfWeek]);

	const firstDayOfWeek = addDays(localFrom, offsetDaysToFirstDate);
	const secondDayOfWeek = addDays(localFrom, offsetDaysToSecondDate);

	return [firstDayOfWeek, secondDayOfWeek];
}

export function formatCNPJ(cnpj: string) {
	if (!cnpj || cnpj === "undefined") return "";

	const cnpjString = cnpj.padStart(14, "0");
	const cnpjMask = cnpjString.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
		"$1.$2.$3/$4-$5"
	);
	return cnpjMask;
}

import AudioStep9Nao from "components/audio/AudioStep9Nao";
import AudioStep9Sim from "components/audio/AudioStep9Sim";
import Decoration from "components/ui/Decoration";
import Eliza from "components/ui/Eliza";
import Fireworks from "components/ui/Fireworks";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect, useState } from "react";

import "./styles.scss";

export default function Step5Desempenho() {
	const { coop, coopInfo, pdgc, categoria } = useCoop();
	const [participa, setParticipa] = useState(-1);
	const { showDialog } = useDialog();

	useEffect(() => {
		if (!coopInfo) return;

		if (coop) {
			setParticipa(coop.length);
		} else {
			setParticipa(0);
		}
	}, [coop, coopInfo]);

	useEffect(() => {
		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("desempenho");
		};
	}, []);

	useEffect(() => {
		if (participa < 0) return;

		document.querySelector("body")?.classList.add("desempenho");

		if (participa === 0) {
			showDialog(
				"Poxa, vi que sua coop não participa do Sistema Desempenho! Para saber como estão os índices da sua cooperativa, fale com a gente e aproveite tudo que o Sistema Desempenho pode oferecer!"
			);
			// document.querySelector("body")?.classList.add("danger");
		} else if (participa > 0) {
			showDialog(
				"Oba, eu vi aqui que a sua coop já participa do Sistema Desempenho!"
			);
			// document.querySelector("body")?.classList.remove("danger");
		}
	}, [coopInfo, participa]);

	if (!coopInfo || participa < 0) return null;

	if (participa > 0) {
		return (
			<>
				{/* <AudioStep9Sim /> */}
				<div id="step9">
					<Fireworks />
					<div className="content text-white text-center">
						<h1 className="text-9xl mt-5">Parabéns</h1>
						<h2 className="text-6xl">{coopInfo.nome_fantasia}</h2>
						<p className="text-5xl mt-3 border-4 p-3 border-neon-blue">
							Sua coop participa do
							<br />
							Sistema Desempenho!
						</p>
					</div>

					<Eliza name="shipHappy" className="eliza animate animate-fly" />

					<Decoration
						name="hands"
						className="hands duration-500 animate-in slide-in-from-bottom"
					/>
				</div>
			</>
		);
	} else {
		return (
			<>
				{/* <AudioStep9Nao /> */}
				<div id="step9">
					<div className="content text-white text-center">
						<h1 className="text-white text-9xl">Que pena!</h1>
						<p className="text-5xl mt-3 border-4 p-3 border-blue-500">
							Sua coop não está participando <br />
							do Sistema Desempenho
						</p>
					</div>

					<Eliza name="sad" className="eliza-sad animate animate-wiggle" />
				</div>
			</>
		);
	}
}

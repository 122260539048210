import { api } from "./api";

const publicHeaders = {
	headers: {
		Authorization:
			"Basic " +
			btoa(
				`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
			),
	},
};

export async function fetchSettings() {
	let settingsHeaders: any = { ...publicHeaders };
	// searchHeaders.headers.Range = "items=0-10000";
	const { data } = await api.get(`database/configuracoes__c`, settingsHeaders);

	let settings: any = {};

	if (data && Array.isArray(data)) {
		data.forEach((setting: any) => {
			settings[setting._id] = setting.active;
		});
	}

	console.log("settings", settings);

	return settings;
}

export async function searchCooperado(cnpj?: string) {
	const isNumber = !isNaN(parseInt(cnpj?.valueOf() || "0"));
	const payload = {
		cnpj: `${isNumber ? parseInt(cnpj?.valueOf() || "0") : cnpj}`,
	};

	let searchHeaders: any = { ...publicHeaders };
	// searchHeaders.headers.Range = "items=0-10000";
	const { data } = await api.post(`find/coop`, payload, searchHeaders);
	return data;
}

export async function fetchDataCredito(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
			},
		},
	];
	const { data } = await api.post(
		`database/desempenho_credito__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataSaude(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
				referencia: {
					$gt: {
						$date: "2022-03-01T00:00:00.000Z",
					},
				},
			},
		},
	];
	const { data } = await api.post(
		`database/desempenho_saude__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataDemais(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
				ramo: {
					$in: [
						"AGROPECUÁRIO",
						"INFRAESTRUTURA",
						"CRÉDITO",
						"TRANSPORTE",
						"SAÚDE",
						"CONSUMO",
					],
				},
			},
		},
	];
	const { data } = await api.post(
		`database/desempenho_demais_ramos__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataDemaisIdentidade(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
				ramo: {
					$in: ["TRABALHO, PRODUÇÃO DE BENS E SERVIÇOS"],
				},
			},
		},
		{
			$sort: {
				ano_aplicacao: -1,
			},
		},
	];
	const { data } = await api.post(
		`database/desempenho_identidade__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataNegociosCoop(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
			},
		},
	];
	const { data } = await api.post(
		`database/negocios_coop__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataSouCoop(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
			},
		},
	];
	const { data } = await api.post(
		`database/sou_coop__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataCoopsRegulares(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
			},
		},
	];
	const { data } = await api.post(
		`database/coops_regulares__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchDataGestao(cnpj?: string) {
	const payload: any = [
		{
			$match: {
				cnpj: cnpj,
			},
		},
		{
			$sort: {
				ano_aplicacao: -1,
			},
		},
	];
	const { data } = await api.post(
		`database/desempenho_gestao__c/aggregate`,
		payload,
		publicHeaders
	);
	return data;
}

export async function fetchRankingCredito() {
	const payload = [
		{
			$lookup: {
				from: "coops_regulares__c",
				localField: "cnpj",
				foreignField: "cnpj",
				as: "coops_regulares",
			},
		},
		{
			$unwind: "$coops_regulares",
		},
		{
			$match: {
				"coops_regulares.uf": "SP",
			},
		},
		{
			$match: {
				id_indicador: {
					$eq: "1050",
				},
			},
		},
		{
			$group: {
				_id: "$cnpj",
				nome_fantasia: {
					$max: "$nome_fantasia",
				},
				total: {
					$sum: "$valor_indicador",
				},
			},
		},
		{
			$sort: {
				total: -1,
			},
		},
	];
	const headers = {
		...publicHeaders.headers,
		Range: "items=0-10000",
	};
	const { data } = await api.post(
		`database/desempenho_credito__c/aggregate`,
		payload,
		{ headers }
	);
	return data;
}

export async function fetchRankingSaude() {
	const payload = [
		{
			$lookup: {
				from: "coops_regulares__c",
				localField: "cnpj",
				foreignField: "cnpj",
				as: "coops_regulares",
			},
		},
		{
			$unwind: "$coops_regulares",
		},
		{
			$match: {
				"coops_regulares.uf": "SP",
			},
		},
		{
			$match: {
				id_indicador: {
					$eq: "863",
				},
				referencia: {
					$gt: {
						$date: "2022-03-01T00:00:00.000Z",
					},
				},
			},
		},
		{
			$group: {
				_id: "$cnpj",
				nome_fantasia: {
					$max: "$nome_fantasia",
				},
				total: {
					$sum: "$valor_indicador",
				},
			},
		},
		{
			$sort: {
				total: -1,
			},
		},
	];
	const headers = {
		...publicHeaders.headers,
		Range: "items=0-10000",
	};
	const { data } = await api.post(
		`database/desempenho_saude__c/aggregate`,
		payload,
		{ headers }
	);
	return data;
}

export async function fetchRankingDemais(ramo: string) {
	const payload = [
		{
			$lookup: {
				from: "coops_regulares__c",
				localField: "cnpj",
				foreignField: "cnpj",
				as: "coops_regulares",
			},
		},
		{
			$unwind: "$coops_regulares",
		},
		{
			$match: {
				"coops_regulares.uf": "SP",
			},
		},
		{
			$match: {
				ramo,
				id_indicador: {
					$eq: "863",
				},
			},
		},
		{
			$group: {
				_id: "$cnpj",
				nome_fantasia: {
					$max: "$nome_fantasia",
				},
				total: {
					$sum: "$valor_indicador",
				},
			},
		},
		{
			$sort: {
				total: -1,
			},
		},
	];
	const headers = {
		...publicHeaders.headers,
		Range: "items=0-10000",
	};
	const { data } = await api.post(
		`database/desempenho_demais_ramos__c/aggregate`,
		payload,
		{ headers }
	);
	return data;
}

export async function fetchRanking(ramo: string) {
	if (ramo === "CRÉDITO") {
		return fetchRankingCredito();
	} else if (ramo === "SAÚDE") {
		return fetchRankingSaude();
	} else {
		return fetchRankingDemais(ramo);
	}
}

export async function fetchMatrizDeRisco(ramo: string) {
	const payload = [
		{
			$match: {
				ramo,
			},
		},
		{
			$group: {
				_id: "$cnpj",
				nome_fantasia: {
					$max: "$nome_fantasia",
				},
				indicadores: {
					$push: {
						id_indicador: "$id_indicador",
						indicador: "$indicador",
						valor_indicador: "$valor_indicador",
					},
				},
			},
		},
	];
	const headers = {
		...publicHeaders.headers,
		Range: "items=0-10000",
	};
	const { data } = await api.post(
		`database/desempenho_demais_ramos__c/aggregate`,
		payload,
		{ headers }
	);
	return data;
}

import AudioStep9Nao from "components/audio/AudioStep9Nao";
import AudioStep9Sim from "components/audio/AudioStep9Sim";
import Decoration from "components/ui/Decoration";
import Eliza from "components/ui/Eliza";
import Fireworks from "components/ui/Fireworks";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect, useState } from "react";

import "./styles.scss";

export default function Step9() {
	const { coop, coopInfo, pdgc, categoria } = useCoop();
	const [participa, setParticipa] = useState(-1);
	const { showDialog } = useDialog();

	useEffect(() => {
		if (pdgc) {
			setParticipa(pdgc.length);
		}
	}, [pdgc]);

	useEffect(() => {
		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("pdgc");
		};
	}, []);

	useEffect(() => {
		if (!coopInfo || participa < 0) return;

		document.querySelector("body")?.classList.add("pdgc");

		if (participa === 0) {
			showDialog(
				"Poxa, vi que a sua coop não está no Governança e Gestão AvaliaCoop! Aproveite tudo o que o Governança e Gestão AvaliaCoop pode oferecer para a sua coop. Fale com a gente!"
			);
			// document.querySelector("body")?.classList.add("danger");
		} else if (participa > 0) {
			showDialog(
				"Parabéns, estou vendo aqui que a sua coop participa do Governança e Gestão AvaliaCoop. Confira os índices da sua coop!"
			);
			// document.querySelector("body")?.classList.remove("danger");
		}
	}, [participa]);

	if (!pdgc || !coopInfo || participa < 0) return null;

	if (participa > 0) {
		return (
			<>
				<div id="step9">
					<Fireworks />
					<div className="content text-white text-center">
						<h1 className="text-9xl mt-5">Parabéns</h1>
						<h2 className="text-6xl">{coopInfo.nome_fantasia}</h2>
						<p className="text-5xl mt-3 border-4 p-3 border-neon-blue">
							Sua coop participa do
							<br />
							Governança e Gestão AvaliaCoop
						</p>
					</div>

					<Eliza name="shipHappy" className="eliza animate animate-fly" />

					<Decoration
						name="hands"
						className="hands duration-500 animate-in slide-in-from-bottom"
					/>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div id="step9">
					<div className="content text-white text-center">
						<h1 className="text-white text-9xl">Que pena!</h1>
						<p className="text-5xl mt-3 border-4 p-3 border-blue-500">
							Sua coop não está no
							<br />
							Governança e Gestão AvaliaCoop
						</p>
					</div>

					<Eliza name="sad" className="eliza-sad animate animate-wiggle" />
				</div>
			</>
		);
	}
}

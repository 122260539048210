import AudioStep2 from "components/audio/AudioStep2";
import Curve from "components/ui/Curve";
import Eliza from "components/ui/Eliza";
import Shape from "components/ui/Shape";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect, useState } from "react";

import "./styles.scss";
import Icon from "components/ui/Icon";

export default function Step2TimelineC() {
	const { coopInfo } = useCoop();
	const [dataFundacao, setDataFundacao] = useState(0);
	const [tempoDeVida, setTempoDeVida] = useState(-1);
	const { showDialog } = useDialog();

	useEffect(() => {
		if (!coopInfo) return;
		try {
			const dataFundacao = new Date(
				coopInfo.constituicao_fundacao
			).getFullYear();
			setDataFundacao(dataFundacao);
		} catch (e) {
			console.log(e);
			setDataFundacao(0);
		}
	}, [coopInfo]);

	useEffect(() => {
		if (!dataFundacao) return;
		setTempoDeVida(new Date().getFullYear() - dataFundacao);
		// setTempoDeVida(0);
	}, [dataFundacao]);

	useEffect(() => {
		if (tempoDeVida < 0) return;
		showDialog(
			`Poxa, que pena. A resposta correta é ${dataFundacao}. Já são ${tempoDeVida} anos de uma história de cooperação e sucesso! E você sabia que a média nacional de vida de uma coop no Brasil é de 20 anos?`
		);
	}, [dataFundacao, tempoDeVida]);

	useEffect(() => {
		document.querySelector("body")?.classList.add("soucoop");
		return () => {
			document.querySelector("body")?.classList.remove("soucoop");
		};
	}, []);

	if (!coopInfo || !dataFundacao) return null;

	return (
		<>
			<AudioStep2 />
			<div id="step2">
				<div className="fundacao">
					<div className="mb-8 w-8 h-8 rounded-full bg-white"></div>
					<h1 className="font-bold text-white text-7xl">{dataFundacao}</h1>
					<h1 className="text-white text-4xl">fundação</h1>
				</div>

				<div className={`${tempoDeVida >= 20 ? "media" : "atual"}`}>
					<div className="mb-8 w-8 h-8 rounded-full bg-white"></div>
					<h1 className="font-bold text-white text-7xl">
						20<span className="font-normal text-4xl"> ANOS</span>
					</h1>
					<h1 className="text-white text-4xl">
						média nacional de <br />
						vida de uma coop
					</h1>
				</div>

				<div className={`${tempoDeVida >= 20 ? "atual" : "media"}`}>
					<div className="mb-8 w-8 h-8 rounded-full bg-white"></div>
					<h1 className="font-bold text-white text-7xl">
						{tempoDeVida}
						<span className="font-normal text-4xl"> ANOS</span>
					</h1>
					<h1 className="text-white text-4xl">sua coop</h1>
				</div>

				<Eliza name="stars" className="eliza animate-in slide-in-from-bottom" />

				{/* <div className="emoji animate-shake">
					<Icon name="sad" className="animate-in zoom-in" />
				</div> */}

				<Curve className="curve" />
			</div>
		</>
	);
}
